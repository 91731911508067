import highlight from './v-highlight';
import tooltip from './v-tooltip';

export { highlight, tooltip };

// Wraps all directives in a Vue plugin ( Vue.use() )
export default {
    install(Vue) {
        Vue.directive('highlight', highlight);
        Vue.directive('tooltip', tooltip);
    },
};
