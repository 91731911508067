export default [
    {
        path: '/enrollments/create',
        component: () => import('../views/shared/Enrollments/EnrollmentsCreate.vue'),
        meta: {
            requiredPermissions: ['read:enrollments', 'create:enrollments'],
        },
        children: [
            {
                name: 'CustomerCreateEnrollment',
                path: '',
                component: () => import('../components/Enrollments/CreateEnrollmentForm.vue'),
                meta: {
                    requiredPermissions: [],
                },
            },
        ],
    },
    {
        name: 'CustomerBilling',
        path: '/billing',
        component: () => import('../views/shared/Billing/Billing.vue'),
        props: { accountId: 'current' },
        meta: {
            requiredPermissions: ['read:invoices', 'read:payments', 'read:credit_memos'],
        },
    },
    {
        name: 'CustomerCheckoutSessionDetails',
        path: '/checkout-sessions/:checkoutSessionId',
        component: () => import('../views/shared/CheckoutSessionDetails/CheckoutSessionDetails.vue'),
        props: true,
        meta: {
            requiredPermissions: ['read:invoices', 'read:payments', 'read:credit_memos'],
        },
    },
    {
        name: 'CustomerCheckoutSessions',
        path: '/checkout-sessions',
        component: () => import('../views/shared/CheckoutSessions/CheckoutSessions.vue'),
        props: { accountId: 'current' },
        meta: {
            requiredPermissions: ['read:invoices', 'read:payments', 'read:credit_memos'],
        },
    },
];
