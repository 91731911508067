/**
 * Formats the input as US dollars (`3.5` => `'$3.50'`)
 * @param {Number} dollars Amount of currency in dollars
 * @returns {String} Formatted string
 */
export const formatDollars = dollars => (+dollars).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

/**
 * Formats the input as US cents (`350` => `'$3.50'`)
 * @param {Number} cents Amount of currency in cents
 * @returns {String} Formatted string
 */
export const formatCents = cents => formatDollars(cents / 100);

/**
 * Formats the input to the thousands place (`350000` => `'3.5k'`)
 * @param {Number} cents Amount of currency in cents
 * @returns {String} Formatted string
 */
export const truncateCents = (cents) => {
    if (cents >= 100000000) {
        return '$' + (cents / 100000000).toFixed(1) + 'm';
    }
    if (cents >= 100000) {
        return '$' + (cents / 100000).toFixed(1) + 'k';
    }
    if (cents < 100000) {
        return formatDollars(Math.max(cents, 0) / 100);
    }
};
