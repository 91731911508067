export default [
    {
        path: '/enrollments/create',
        component: () => import('../views/shared/Enrollments/EnrollmentsCreate.vue'),
        meta: {
            requiredPermissions: ['read:enrollments', 'create:enrollments'],
        },
        children: [
            {
                name: 'AdminEnrollmentAccountSelection',
                path: 'select-account',
                component: () => import('../views/admin/AccountSelection.vue'),
                meta: {
                    requiredPermissions: ['read:accounts'],
                },
                props: { header: 'Device Tracking and Limited Warranty Enrollment' },
            },
            {
                name: 'AdminCreateEnrollment',
                path: '',
                component: () => import('../components/Enrollments/CreateEnrollmentForm.vue'),
                meta: {
                    requiredPermissions: [],
                },
            },
        ],
    },
    {
        path: '/orders',
        component: () => import('../views/admin/Orders/Orders.vue'),
        children: [
            {
                name: 'AdminOrdersList',
                path: '',
                component: () => import('../views/admin/Orders/OrdersList.vue'),
                meta: {
                    requiredPermissions: ['read:orders'],
                },
            },
            {
                name: 'AdminOrderAccountSelection',
                path: 'select-customer',
                component: () => import('../views/admin/AccountSelection.vue'),
                meta: {
                    requiredPermissions: ['read:accounts'],
                },
                props: { header: 'New Order' },
            },
            {
                name: 'AdminCreateOrder',
                path: 'create',
                component: () => import('../views/admin/Orders/OrdersCreate.vue'),
                meta: {
                    requiredPermissions: ['create:orders'],
                },
            },
            {
                name: 'AdminOrderDetails',
                path: ':id',
                component: () => import('../views/admin/Orders/OrdersDetail.vue'),
                meta: {
                    requiredPermissions: ['read:orders'],
                },
            },
        ],
    },
    {
        name: 'AdminBilling',
        path: '/billing',
        component: () => import('../views/shared/Billing/BillingAccountSelection.vue'),
        children: [
            {
                name: 'AdminBillingAccountSelection',
                path: '',
                component: () => import('../views/admin/AccountSelection.vue'),
                props: { header: 'Billing' },
            },
            {
                name: 'AdminBillingDetails',
                path: ':accountId',
                component: () => import('../views/shared/Billing/Billing.vue'),
                props: true,
            },
            {
                name: 'AdminCheckoutSessions',
                path: ':accountId/checkout-sessions',
                component: () => import('../views/shared/CheckoutSessions/CheckoutSessions.vue'),
                props: true,
            },
            {
                name: 'AdminCheckoutSessionDetails',
                path: ':accountId/checkout-sessions/:checkoutSessionId',
                component: () => import('../views/shared/CheckoutSessionDetails/CheckoutSessionDetails.vue'),
                props: true,
            },
        ],
        meta: {
            requiredPermissions: ['read:invoices', 'read:payments', 'read:credit_memos'],
        },
        redirect: () => ({ name: 'AdminBillingAccountSelection' }),
    },
    {
        name: 'DisbursementsList',
        path: '/disbursements',
        component: () => import('../views/admin/Disbursements/DisbursementsList.vue'),
        meta: {
            requiredPermissions: ['read:payouts'],
        },
    },
    {
        name: 'DisbursementsDetail',
        path: '/disbursements/:disbursementId',
        component: () => import('../views/admin/Disbursements/DisbursementsDetail.vue'),
        props: true,
        meta: {
            requiredPermissions: ['read:payouts', 'read:payments.any'],
        },
    },
    {
        name: 'Reports',
        path: '/reports',
        component: () => import('../views/admin/Reports/Reports.vue'),
        meta: {
            requiredPermissions: ['read:accounts.any'],
        },
    },
];
