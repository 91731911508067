import Login from '../views/Login.vue';
import router from './index';

export default [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { noNav: true },
        async beforeEnter(_to, _from, next) {
            if (await router.app.$auth.isAuthenticated()) {
                next(getDefaultRoute());
            } else {
                next();
            }
        },
    },
    {
        path: '/authorized',
        meta: { noNav: true },
        async beforeEnter(to, _from, next) {
            try {
                const { returnToPath } = await router.app.$auth.authorizedCallback(to.fullPath);

                next(returnToPath || getDefaultRoute());
            } catch (err) {
                next({ name: 'login', params: { authError: true }});
            }
        },
    },
];

const hasPermissionsForRoute = (route) => {
    const requiredPermissions = route.matched
        .flatMap(route => route.meta.requiredPermissions)
        .filter(permission => typeof permission === 'string' && permission.length > 0);

    return router.app.$auth.hasPermissions(...requiredPermissions);
};

export const getDefaultRoute = () =>
    ({ name: (router.app.$auth.isCustomerUser() ? 'CustomerBilling' : 'AdminBilling') });

export const authGuard = async (to, _from, next) => {
    // Authentication-related routes should be exempt from checks
    const needsAuth = !(['/login', '/authorized'].includes(to.path));
    if (!needsAuth) {
        return next();
    }

    if (!await router.app.$auth.isAuthenticated()) {
        // Capture the path for the return-to query param, unless it's one of the error pages
        const nonReturnPaths = ['/404', '/insufficient-permissions'];
        const returnTo = nonReturnPaths.includes(to.path) ? undefined : to.fullPath;

        return next({ name: 'login', query: { 'return-to': returnTo }});
    }

    // Check that admins only can access admin routes, and customers only for customer routes
    if (to.path.startsWith('/admin') && !router.app.$auth.isAdminUser()) {
        return next({ name: 'InsufficientPermissions' });
    } else if (to.path.startsWith('/customer') && !router.app.$auth.isCustomerUser()) {
        return next({ name: 'InsufficientPermissions' });
    }

    if (hasPermissionsForRoute(to)) {
        return next();
    } else {
        return next({ name: 'InsufficientPermissions' });
    }
};
