import { formatCents, formatDollars, truncateCents } from './currency';
import { formatDate, formatISODate } from './date';

export { formatDollars, formatCents, formatDate, formatISODate, truncateCents };

// Wraps the filters in a Vue plugin ( Vue.use() )
export default {
    install(Vue) {
        Vue.filter('formatCents', formatCents);
        Vue.filter('formatDollars', formatDollars);
        Vue.filter('formatDate', formatDate);
        Vue.filter('formatISODate', formatISODate);
        Vue.filter('truncateCents', truncateCents);
    },
};
