import './axios-interceptor';
import * as Sentry from '@sentry/vue';
import AlertsManager from './alerts';
import App from './App.vue';
import { auth0 } from './auth0';
import directives from './directives';
import filters from './filters';
import router from './router';
import Vue from 'vue';

const DEBUG = import.meta.env.NODE_ENV !== 'production';

if (!DEBUG) {
    Sentry.init({
        dsn: import.meta.env.VUE_APP_SENTRY_DSN,
        environment: import.meta.env.VUE_APP_SENTRY_ENVIRONMENT,
        Vue,
    });
}

Vue.config.productionTip = false;

// Main application boot function
(async () => {
    Vue.use(await auth0);
    Vue.use(AlertsManager);
    Vue.use(directives);
    Vue.use(filters);

    new Vue({
        router,
        render: h => h(App),
    }).$mount('#app');
})();
