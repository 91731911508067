import { format, parse, parseISO } from 'date-fns';

/**
 * Parses a given ISO date string and formats it using a given pattern
 * @param {String} dateStr An ISO 8601 formatted date
 * @param {String} formatStr Formatting pattern to use (see date-fns docs)
 * @returns {String} The formatted date
 */
export const formatISODate = (dateStr, formatStr) => format(parseISO(dateStr), formatStr);

/**
 * Parses a given date string and formats it using a given pattern
 * @param {String} dateStr A date string
 * @param {String} inputFormat Parsing pattern to use (see date-fns docs)
 * @param {String} outputFormat Formatting pattern to use (see date-fns docs)
 * @returns {String} The formatted date
 */
export const formatDate = (dateStr, inputFormat, outputFormat) =>
    format(parse(dateStr, inputFormat, new Date()), outputFormat);
