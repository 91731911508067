<template>
    <div id="app">
        <AlertContainer />
        <div class="container" data-layout="container">
            <Navbar v-if="$route.matched.length && !$route.meta.noNav" />
            <div class="content mt-sm-0" :class="[$auth.isCustomerUser() ? 'mt-6' : 'mt-3']">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
    import AlertContainer from '@/components/AlertContainer.vue';
    import Navbar from '@/components/Navbar/Navbar.vue';

    export default {
        components: {
            Navbar,
            AlertContainer,
        },
    };
</script>

<style>
    .d-table-column {
        display: table-column !important;
    }

    @media (min-width:992px){
        .d-lg-table-column {
            display: table-column !important
        }
    }

    .btn-link {
        font-family: inherit;
        font-size: inherit;
        padding: 0;
    }
</style>
