<template>
    <nav class="navbar navbar-light navbar-glass navbar-top sticky-kit navbar-expand-lg" @focusout="closeNavBar">
        <button ref="navButton"
                class="btn navbar-toggler-humburger-icon navbar-toggler mr-1 mr-sm-3"
                type="button"
                data-toggle="collapse"
                data-target="#navbarStandard"
                aria-controls="navbarStandard"
                aria-expanded="false"
                aria-label="Toggle Navigation">
            <span class="navbar-toggle-icon">
                <span class="toggle-line" />
            </span>
        </button>
        <router-link
            :to="{ name: $auth.isCustomerUser() ? 'CustomerBilling' : 'AdminBilling' }"
            class="navbar-brand"
        >
            <div class="d-flex align-items-center py-3">
                <img src="/img/logo_color.svg" alt="Sientra" height="25">
            </div>
        </router-link>
        <div class="collapse navbar-collapse" id="navbarStandard">
            <ul class="navbar-nav">
                <NavItem
                    name="Billing"
                    :route="{ name: $auth.isCustomerUser() ? 'CustomerBilling' : 'AdminBilling' }"
                />
                <template v-if="$auth.isCustomerUser()">
                    <div v-if="$auth.hasPermissions('read:enrollments', 'create:enrollments')"
                         class="d-flex align-items-center">
                        <NavItem
                            name="Enrollment"
                            :route="{ name: 'CustomerCreateEnrollment' }"
                        />
                        <span class="badge badge-pill badge-soft-success">New</span>
                    </div>
                </template>
                <template v-else-if="$auth.isAdminUser()">
                    <NavItem
                        v-if="$auth.hasPermissions('read:payouts')"
                        name="Disbursements"
                        :route="{ name: 'DisbursementsList' }"
                    />
                    <NavItem
                        v-if="$auth.hasPermissions('read:enrollments', 'create:enrollments')"
                        name="Enrollment"
                        :route="{ name: 'AdminEnrollmentAccountSelection' }"
                    />
                    <NavItem
                        v-if="$auth.hasPermissions('read:orders')"
                        name="Orders"
                        :route="{ name: 'AdminOrdersList' }"
                    />
                    <NavItem
                        v-if="$auth.hasPermissions('read:accounts.any') && (
                            $auth.hasPermissions('read:payments.any')
                            || $auth.hasPermissions('read:orders.any')
                            || $auth.hasPermissions('read:enrollments')
                        )"
                        name="Reports"
                        :route="{ name: 'Reports' }"
                    />
                </template>
            </ul>
        </div>
        <ul class="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center">
            <NavContact v-if="$auth.isCustomerUser()" />
            <NavUser />
        </ul>
    </nav>
</template>

<script>
    import NavContact from './NavContact.vue';
    import NavItem from './NavItem.vue';
    import NavUser from './NavUser.vue';

    export default {
        name: 'Navbar',
        components: {
            NavItem,
            NavUser,
            NavContact,
        },
        data: () => ({
            openingAccountOverview: false,
            openingAccountOverviewError: null,
            // ---
            smallScreen: true, // Used to control navbar button clicks, which only appears on a small screen
        }),
        watch: {
            '$route'() {
                // Close the navbar when navigating to a new page and on a device with a small screen.
                this.closeNavBar();
            },
        },
        created() {
            // Listen for the screen size changes so our JavaScript can adjust accordingly.
            const mediaQueryList = window.matchMedia('(max-width: 992px)');
            mediaQueryList.onchange = (event) => this.smallScreen = event.matches;
            this.smallScreen = mediaQueryList.matches;
        },
        methods: {
            /**
             * Returns 'true' if the navbar is collapsed.
             * @returns {boolean}
             */
            isNavbarCollapsed() {
                return this.$refs.navButton.classList.contains('collapsed');
            },
            /**
             * Closes the navbar.
             * @static
             */
            closeNavBar() {
                if (this.smallScreen && !this.isNavbarCollapsed()) {
                    this.$refs.navButton.click();
                }
            },
        },
    };
</script>
