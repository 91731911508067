const highlight = (el, binding) => {
    const original = el.dataset.original;
    if (binding.value) {
        const index = original.toLowerCase().indexOf(binding.value.toLowerCase());
        if (index >= 0) {
            el.innerHTML = original.substring(0, index)
                + `<mark>${original.substring(index, index + binding.value.length)}</mark>`
                + original.substring(index + binding.value.length);
        } else {
            el.innerHTML = original;
        }
    } else {
        el.innerHTML = original;
    }
};

export default {
    bind: (el) => {
        el.dataset.original = el.innerText;
    },
    inserted: (el, binding) => {
        highlight(el, binding);
    },
    update: (el, binding) => {
        highlight(el, binding);
    },
    unbind: (el) => {
        el.innerHTML = el.dataset.original;
        delete el.dataset.original;
    },
};
