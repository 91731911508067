<template>
    <div class="row flex-center min-vh-100 py-6">
        <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div v-if="$route.params.authError" class="alert alert-danger alert-dismissible" role="alert">
                <i class="fas fa-exclamation-circle mr-1" /> An authentication error ocurred.
                <button class="close" type="button" data-dismiss="alert" aria-label="Close">
                    <span class="font-weight-light" aria-hidden="true">×</span>
                </button>
            </div>
            <div class="card bg-card-gradient text-center" style="color: #545554">
                <div class="card-body p-5">
                    <div class="d-flex py-3">
                        <img src="/img/logo_color.svg" height="40" class="m-auto">
                    </div>
                    <p class="opacity-75 mt-2">
                        Authentication is required to access this page. Please sign in to continue.
                    </p>
                    <button class="btn btn-light btn-block mt-4" @click="loginAsCustomer">Log in</button>
                </div>
            </div>
            <div class="text-center mt-3">
                <button class="btn btn-link fs--1 text-600" @click="loginAsAdmin">Administrator Login</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            loginAsAdmin() {
                const returnToPath = this.$route.query['return-to'] || undefined;
                this.$auth.adminLogin({ returnToPath });
            },
            loginAsCustomer() {
                const returnToPath = this.$route.query['return-to'] || undefined;
                this.$auth.customerLogin({ returnToPath });
            },
        },
    };
</script>

<style scoped>
    .bg-card-gradient {
        background-image: linear-gradient(-45deg, rgba(149, 156, 163, .5), rgba(223, 223, 223, .5));
        background-position: center;
    }
</style>
