import { auth0 } from './auth0';
import axios from 'axios';

axios.interceptors.request.use(async config => {
    config.baseURL = import.meta.env.VUE_APP_API_BASE;

    const accessToken = await (await auth0).getAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
});
