<template>
    <li class="nav-item">
        <router-link class="nav-link" :to="route" active-class="active">
            {{ name }}
        </router-link>
    </li>
</template>

<script>
    export default {
        name: 'NavItem',
        props: {
            name: {
                type: String,
                required: true,
            },
            route: {
                type: [String, Object],
                required: true,
            },
        },
    };
</script>
