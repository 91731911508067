import authRoutes, { authGuard, getDefaultRoute } from './auth';
import adminRoutes from './admin';
import customerRoutes from './customer';

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: () => getDefaultRoute(),
    },
    {
        name: '404',
        path: '/404',
        component: () => import('../views/404.vue'),
    },
    {
        name: 'InsufficientPermissions',
        path: '/insufficient-permissions',
        component: () => import('../views/InsufficientPermissions.vue'),
    },

    ...authRoutes,

    // Adds the appropriate path prefix to route records
    ...adminRoutes.map(record => ({ ...record, path: '/admin' + record.path })),
    ...customerRoutes.map(record => ({ ...record, path: '/customer' + record.path })),

    {
        path: '*',
        redirect: '404',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes,
});

router.beforeEach(authGuard);

// Track page change events with umami
router.afterEach((to, from) => {
    /* global umami */
    if (typeof umami === 'function') {
        if (to.path !== from.path) {
            umami.trackView(to.path);
        }
    }
});

export default router;
