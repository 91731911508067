<template>
    <li class="nav-item dropdown dropdown-on-hover mx-2">
        <button class="btn btn-outline-primary btn-sm dropdown-toggle" data-toggle="dropdown" type="button">
            Support
        </button>
        <div class="dropdown-menu dropdown-menu-right py-0">
            <div class="card shadow-none border-0" style="min-width: 18rem;">
                <div class="card-body d-flex flex-column">
                    <a class="btn btn-outline-secondary btn-sm d-flex align-items-center pr-4 mb-2"
                       href="mailto:customer.experience@sientra.com">
                        <i class="fa fa-envelope mr-2" />
                        <span class="mx-auto">customer.experience@sientra.com</span>
                    </a>
                    <a class="btn btn-outline-secondary btn-sm d-flex align-items-center pr-4 mb-2"
                       href="tel:+18887080808">
                        <i class="fa fa-phone mr-2" />
                        <span class="mx-auto">(888) 708-0808</span>
                    </a>
                    <li><hr class="dropdown-divider mb-3"></li>
                    <a class="btn btn-outline-secondary btn-sm d-flex align-items-center pr-4 mb-2"
                       href="https://www.sientraorangeportal.com/signin.asp">
                        <i class="fas fa-globe mr-2" />
                        <span class="mx-auto">Previous Invoices</span>
                    </a>
                    <a class="btn btn-outline-secondary btn-sm d-flex align-items-center pr-4"
                       href="mailto:accountsreceivable@sientra.com">
                        <i class="fa fa-envelope mr-2" />
                        <span class="mx-auto">Account Inquiry</span>
                    </a>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    export default {
        name: 'NavContact',
    };
</script>
